import styled from '@emotion/styled';
import tw from 'twin.macro';

const TitleStyled = styled.h1`
  ${tw`text-center text-3xl text-primary-900 mb-5 mt-5 font-thin leading-tight`}

  & b {
    ${tw`font-black`}
  }
`;

export { TitleStyled };
