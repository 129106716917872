import React, { useEffect, useContext, memo } from 'react';

import { CreditcardContext } from '../../context/creditcard.context';
import { isUndefined, isTrue, isGreaterThan, diffTwoDatesInSeconds as diff } from '../../utils/functions';
import { navigateToError, redirectWhenStatusPageIsNotValid } from '../../utils/navigate';

import { TitleStyled } from '../../styles/pages/customer';
import { Counter } from '../../components/counter';
import { CustomerForm } from '../../components/forms/customer';
import { LoadingComponent } from '../../components/loading';

import Layout from '../../components/responsive/layout';
import Topbar from '../../components/responsive/topbar';
import { LangContext } from '../../context/lang.context';
import { graphql, useStaticQuery } from 'gatsby';

const RenderLayout = memo(
  ({ render, token }: any) => {
    if (!render) {
      return <LoadingComponent />;
    }
    const { t } = useContext(LangContext);

    const data = useStaticQuery(graphql`
      query {
        allI18NJson {
          nodes {
            locale
            clientId
           CUSTOMER {
             TITLE_1
             TITLE_2
             TITLE_3
            }
          }
        }
      }
    `);
    return (
      <Layout>
        <Topbar />
        <div className="content">
          <TitleStyled>
            {t(data).CUSTOMER.TITLE_1} <b>{t(data).CUSTOMER.TITLE_2}</b>
            {t(data).CUSTOMER.TITLE_3}
          </TitleStyled>
          <Counter visible={false} height="0" paddingBottom="3" />

          <CustomerForm token={token} />
        </div>
      </Layout>
    );
  },
  ({ render: prev }, { render: next }) => prev === next
);

const CustomerPage = ({ getToken }: PageProps) => {
  const { infoUser, timeStamp, getCustomer, loading } = useContext(CreditcardContext);
  const allValid = [Object.entries(infoUser).length, timeStamp?.length ?? 0].every(isGreaterThan(0));
  const token: string = getToken();

  useEffect(() => {
    if (!infoUser?.expirationTime && token) {
      getCustomer?.(token);
    } else if (isUndefined(token)) {
      navigateToError()?.();
    }
  }, []);

  useEffect(() => {
    if (allValid) {
      redirectWhenStatusPageIsNotValid(infoUser, timeStamp);
    }
  }, [infoUser, timeStamp]);

  const dateDiff = diff(timeStamp ?? '', infoUser?.expirationTime ?? '');
  const render = [allValid, !!dateDiff, !infoUser.status, !loading].every(isTrue);
  return <RenderLayout render={render} token={token} />;
};

export default CustomerPage;
