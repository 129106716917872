import React, { ChangeEvent, useContext } from 'react';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';

import creditCardService from '../../services/endpoints';

import { createMask, MASK_CHAR_CARD } from '../../utils/masks';
import { CreditcardContext } from '../../context/creditcard.context';
import { navigateToFailed, navigateToSuccess } from '../../utils/navigate';
import pages from '../../constants/pages.json';

import { ArticleStyled, FormCustomerStyled, SubmitStyled } from './styles';
import { FormProps, RenderInputProps } from './models';
import { Input, Select } from '../inputs';
import { convertFromAppToCustomer } from '../../utils/converts';
import { graphql, useStaticQuery } from 'gatsby';
import { LangContext } from '../../context/lang.context';

const taxTypeList = ['CUIT', 'CUIL'];

const documentTypeList = [
  { value: 'DNI', title: 'DNI' },
  { value: 'PASAPORTE', title: 'Pasaporte' },
  { value: 'CEDULA', title: 'Cédula de identificación' },
  { value: 'LIBCIV', title: 'Libreta Cívica' },
  { value: 'LIBENR', title: 'Libreta de Enrolamiento' },
];

const provinces = [
  'Buenos Aires',
  'Catamarca',
  'Chaco',
  'Chubut',
  'Ciudad Autonoma de Buenos Aires',
  'Cordoba',
  'Corrientes',
  'Entre Rios',
  'Formosa',
  'Jujuy',
  'La Pampa',
  'La Rioja',
  'Mendoza',
  'Misiones',
  'Neuquen',
  'Rio Negro',
  'Salta',
  'San Juan',
  'San Luis',
  'Santa Cruz',
  'Santa Fe',
  'Santiago del Estero',
  'Tierra del Fuego',
  'Tucuman',
];

const RenderName = ({ data, formik, show }: RenderInputProps) => {
  if (!show) return null;
  const { t } = useContext(LangContext);
  return (
    <ArticleStyled>
      <Input
        title={t(data).CUSTOMER.FORM.NAME}
        errorMessage={formik.errors.name}
        onChange={formik.handleChange('name')}
        onFocus={() => formik.setFieldTouched('name', false)}
        touchedInput={formik.touched.name}
        value={formik.values.name}
        mask={createMask('NAME', 40)}
        placeholderChar={MASK_CHAR_CARD}
        guide={false}
        suffixIcon={true}
      />
    </ArticleStyled>
  );
};

const RenderLastName = ({ data, formik, show }: RenderInputProps) => {
  if (!show) return null;
  const { t } = useContext(LangContext);

  return (
    <ArticleStyled>
      <Input
        title={t(data).CUSTOMER.FORM.LASTNAME}
        errorMessage={formik.errors.lastname}
        onChange={formik.handleChange('lastname')}
        onFocus={() => formik.setFieldTouched('lastname', false)}
        touchedInput={formik.touched.lastname}
        value={formik.values.lastname}
        mask={createMask('NAME', 40)}
        placeholderChar={MASK_CHAR_CARD}
        guide={false}
        suffixIcon={true}
      />
    </ArticleStyled>
  );
};

const RenderDocumentType = ({ data, formik, show }: RenderInputProps) => {
  if (!show) return null;
  const { t } = useContext(LangContext);
  return (
    <ArticleStyled>
      <Select
        title={t(data).CUSTOMER.FORM.DOCUMENT_TYPE}
        options={documentTypeList}
        onChange={(e: ChangeEvent<any>) => {
          formik.setFieldValue('documentType', e.target.value);
          formik.setFieldTouched('documentType', false);
          formik.setFieldValue('documentNumber', '');
        }}
        touchedInput={formik.touched.documentType}
        errorMessage={formik.errors.documentType}
        defaultOption={formik.values.documentType}
        emptySelect={t(data).CUSTOMER.FORM.EMPTY_SELECT}
        removeDefaultValue
      />
    </ArticleStyled>
  );
};

const RenderDocumentNumber = ({ data, formik, show }: RenderInputProps) => {
  if (!show) return null;
  const { t } = useContext(LangContext);
  const { documentType } = formik.values;
  let mask = createMask('NUMBER', 8);

  if (documentType === 'PASAPORTE') {
    mask = createMask('PASSPORT', 9);
  }

  return (
    <ArticleStyled>
      <Input
        title={t(data).CVU.FORM.DOCUMENT_NUMBER}
        errorMessage={formik.errors.documentNumber}
        onChange={formik.handleChange('documentNumber')}
        onFocus={() => formik.setFieldTouched('documentNumber', false)}
        touchedInput={formik.touched.documentNumber}
        value={formik.values.documentNumber}
        mask={mask}
        placeholderChar={MASK_CHAR_CARD}
        guide={false}
        suffixIcon={true}
      />
    </ArticleStyled>
  );
};

const RenderTaxType = ({ data, formik, show }: RenderInputProps) => {
  if (!show) return null;
  const { t } = useContext(LangContext);
  return (
    <ArticleStyled>
      <Select
        title={t(data).CUSTOMER.FORM.TAX_TYPE}
        options={taxTypeList}
        onChange={(e: ChangeEvent<any>) => {
          formik.setFieldValue('taxType', e.target.value);
          formik.setFieldTouched('taxType', false);
        }}
        touchedInput={formik.touched.taxType}
        errorMessage={formik.errors.taxType}
        defaultOption={formik.values.taxType}
        emptySelect={t(data).CUSTOMER.FORM.EMPTY_SELECT_TAX_TYPE}
        removeDefaultValue
      />
    </ArticleStyled>
  );
};

const RenderTaxNumber = ({ data, formik, show }: RenderInputProps) => {
  if (!show) return null;
  const { t } = useContext(LangContext);
  return (
    <ArticleStyled>
      <Input
        title={t(data).CUSTOMER.FORM.TAX_NUMBER}
        errorMessage={formik.errors.taxNumber}
        onChange={formik.handleChange('taxNumber')}
        onFocus={() => formik.setFieldTouched('taxNumber', false)}
        touchedInput={formik.touched.taxNumber}
        value={formik.values.taxNumber}
        mask={createMask('NUMBER', 11)}
        placeholderChar={MASK_CHAR_CARD}
        guide={false}
        suffixIcon={true}
      />
    </ArticleStyled>
  );
};

const RenderEmail = ({ data, formik, show }: RenderInputProps) => {
  if (!show) return null;
  const { t } = useContext(LangContext);
  return (
    <ArticleStyled>
      <Input
        title={t(data).CUSTOMER.FORM.EMAIL}
        errorMessage={formik.errors.email}
        onChange={formik.handleChange('email')}
        onFocus={() => formik.setFieldTouched('email', false)}
        touchedInput={formik.touched.email}
        value={formik.values.email}
        suffixIcon={true}
      />
    </ArticleStyled>
  );
};

const RenderLocation = ({ data, formik, show }: RenderInputProps) => {
  if (!show) return null;
  const { t } = useContext(LangContext);
  return (
    <ArticleStyled>
      <Input
        title={t(data).CUSTOMER.FORM.LOCATION}
        errorMessage={formik.errors.location}
        onChange={formik.handleChange('location')}
        onFocus={() => formik.setFieldTouched('location', false)}
        touchedInput={formik.touched.location}
        value={formik.values.location}
        mask={createMask('NAME', 40)}
        placeholderChar={MASK_CHAR_CARD}
        guide={false}
        suffixIcon={true}
      />
    </ArticleStyled>
  );
};

const RenderPostalCode = ({ data, formik, show }: RenderInputProps) => {
  if (!show) return null;
  const { t } = useContext(LangContext);
  return (
    <ArticleStyled>
      <Input
        title={t(data).CUSTOMER.FORM.POSTAL_CODE}
        errorMessage={formik.errors.postalCode}
        onChange={formik.handleChange('postalCode')}
        onFocus={() => formik.setFieldTouched('postalCode', false)}
        touchedInput={formik.touched.postalCode}
        value={formik.values.postalCode}
        suffixIcon={true}
      />
    </ArticleStyled>
  );
};

const RenderProvince = ({ data, formik, show }: RenderInputProps) => {
  if (!show) return null;
  const { t } = useContext(LangContext);
  return (
    <ArticleStyled>
      <Select
        title={t(data).CUSTOMER.FORM.PROVINCE}
        options={provinces}
        onChange={(e: ChangeEvent<any>) => {
          formik.setFieldValue('province', e.target.value);
          formik.setFieldTouched('province', false);
        }}
        touchedInput={formik.touched.province}
        errorMessage={formik.errors.province}
        defaultOption={formik.values.province}
        emptySelect={t(data).CUSTOMER.FORM.EMPTY_SELECT}
        removeDefaultValue
      />
    </ArticleStyled>
  );
};

const RenderStreet = ({ data, formik, show }: RenderInputProps) => {
  if (!show) return null;
  const { t } = useContext(LangContext);
  return (
    <ArticleStyled>
      <Input
        title={t(data).CUSTOMER.FORM.STREET}
        errorMessage={formik.errors.street}
        onChange={formik.handleChange('street')}
        onFocus={() => formik.setFieldTouched('street', false)}
        touchedInput={formik.touched.street}
        value={formik.values.street}
        mask={createMask('NUMBER_CHAR', 25)}
        placeholderChar={MASK_CHAR_CARD}
        guide={false}
        suffixIcon={true}
      />
    </ArticleStyled>
  );
};

const RenderStreetNumber = ({ data, formik, show }: RenderInputProps) => {
  if (!show) return null;
  const { t } = useContext(LangContext);
  return (
    <ArticleStyled>
      <Input
        title={t(data).CUSTOMER.FORM.STREET_NUMBER}
        errorMessage={formik.errors.streetNumber}
        onChange={formik.handleChange('streetNumber')}
        onFocus={() => formik.setFieldTouched('streetNumber', false)}
        touchedInput={formik.touched.streetNumber}
        value={formik.values.streetNumber}
        mask={createMask('NUMBER_CHAR', 5)}
        placeholderChar={MASK_CHAR_CARD}
        guide={false}
        suffixIcon={true}
      />
    </ArticleStyled>
  );
};

const CustomerForm = ({ token }: FormProps & { token: string }) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          CUSTOMER {
            FAILED {
              DESCRIPTION_1
              DESCRIPTION_2
              DESCRIPTION_3
              TITLE_1
              TITLE_2
              DUPLICATE
            }
            FORM {
              VALIDATION {
                DOCUMENT_NUMBER
                DOCUMENT_TYPE
                EMAIL
                LASTNAME
                LOCATION
                NAME
                POSTAL_CODE
                PROVINCE
                STREET_NUMBER
                STREET
                TAX_NUMBER
                TAX_TYPE
              }
              DEPARTMENT
              DOCUMENT_NUMBER
              EMAIL
              DOCUMENT_TYPE
              EMPTY_SELECT
              LASTNAME
              LOCATION
              POSTAL_CODE
              NAME
              PROVINCE
              STREET
              STREET_NUMBER
              SUBMIT
              TAX_NUMBER
              TAX_TYPE
            }
            SUCCESS {
              DESCRIPTION_1
              DESCRIPTION_2
              TITLE
            }
            TITLE_1
            TITLE_2
            TITLE_3
          }
          CVU {
            FORM {
              DOCUMENT_NUMBER
            }
          }
        }
      }
    }
  `);

  const { infoUser, setLoading } = useContext(CreditcardContext);
  const {
    chatbotIntent,
    documentNumber,
    documentType,
    email,
    lastname,
    location,
    name,
    postalCode,
    province,
    street,
    streetNumber,
    taxNumber,
    taxType,
  } = infoUser;

  const rules = {
    name: Yup.string().required(t(data).CUSTOMER.FORM.VALIDATION.NAME),
    lastname: Yup.string().required(t(data).CUSTOMER.FORM.VALIDATION.LASTNAME),
    email: Yup.string()
      .required(t(data).CUSTOMER.FORM.VALIDATION.EMAIL)
      .email(t(data).CUSTOMER.FORM.VALIDATION.EMAIL)
      .max(65, t(data).CUSTOMER.FORM.VALIDATION.EMAIL),
    location: Yup.string().required(t(data).CUSTOMER.FORM.VALIDATION.LOCATION),
    postalCode: Yup.string().required(t(data).CUSTOMER.FORM.VALIDATION.POSTAL_CODE),
    province: Yup.string().required(t(data).CUSTOMER.FORM.VALIDATION.PROVINCE),
    street: Yup.string().required(t(data).CUSTOMER.FORM.VALIDATION.STREET),
    streetNumber: Yup.string().required(t(data).CUSTOMER.FORM.VALIDATION.STREET_NUMBER),
    taxType: Yup.mixed()
      .required(t(data).CUSTOMER.FORM.VALIDATION.TAX_TYPE)
      .oneOf([...taxTypeList]),
    taxNumber: Yup.string()
      .required(t(data).CUSTOMER.FORM.VALIDATION.TAX_NUMBER)
      .validCuitCuil(t(data).CUSTOMER.FORM.VALIDATION.TAX_NUMBER),
    documentType: Yup.mixed()
      .required(t(data).CUSTOMER.FORM.VALIDATION.DOCUMENT_TYPE)
      .oneOf([...documentTypeList.map(({ value }) => value)]),
    documentNumber: Yup.lazy(() => {
      const { documentType } = formik.values;

      if (documentType === 'PASAPORTE') {
        return Yup.string()
          .required(t(data).CUSTOMER.FORM.VALIDATION.DOCUMENT_NUMBER)
          .min(9, t(data).CUSTOMER.FORM.VALIDATION.DOCUMENT_NUMBER);
      }

      return Yup.string()
        .required(t(data).CUSTOMER.FORM.VALIDATION.DOCUMENT_NUMBER)
        .min(7, t(data).CUSTOMER.FORM.VALIDATION.DOCUMENT_NUMBER);
    }),
  };

  const formik = useFormik({
    initialValues: {
      documentNumber: '',
      documentType: '',
      email: '',
      lastname: '',
      location: '',
      name: '',
      postalCode: '',
      province: '',
      street: '',
      streetNumber: '',
      taxNumber: '',
      taxType: '',
    },
    validationSchema: Yup.object({
      ...(documentNumber === '' ? { documentNumber: rules.documentNumber } : {}),
      ...(documentType === '' ? { documentType: rules.documentType } : {}),
      ...(email === '' ? { email: rules.email } : {}),
      ...(lastname === '' ? { lastname: rules.lastname } : {}),
      ...(location === '' ? { location: rules.location } : {}),
      ...(name === '' ? { name: rules.name } : {}),
      ...(postalCode === '' ? { postalCode: rules.postalCode } : {}),
      ...(province === '' ? { province: rules.province } : {}),
      ...(street === '' ? { street: rules.street } : {}),
      ...(streetNumber === '' ? { streetNumber: rules.streetNumber } : {}),
      ...(taxNumber === '' ? { taxNumber: rules.taxNumber } : {}),
      ...(taxType === '' ? { taxType: rules.taxType } : {}),
    }),
    onSubmit: async (values: FormikValues) => {
      setLoading?.(true);

      try {
        const paramsRequest = convertFromAppToCustomer(values);
        await creditCardService.patchCustomer(paramsRequest, token);

        const state = {
          from: pages.SUCCESS_PAGE.UPDATE_CUSTOMER,
          messageFrom: chatbotIntent,
        };

        navigateToSuccess({ state, callback: () => setLoading?.(false) })?.();
      } catch {
        const state = {
          from: pages.FAILED_PAGE.UPDATE_CUSTOMER,
          messageFrom: chatbotIntent,
        };

        navigateToFailed({ state, callback: () => setLoading?.(false) })?.();
      }
    },
  });

  return (
    <FormCustomerStyled autoComplete="false" onSubmit={formik.handleSubmit} className="w-full">
      <RenderName data={data} formik={formik} show={name === ''} />
      <RenderLastName data={data} formik={formik} show={lastname === ''} />
      <RenderDocumentType data={data} formik={formik} show={documentType === ''} />
      <RenderDocumentNumber data={data} formik={formik} show={documentNumber === ''} />
      <RenderTaxType data={data} formik={formik} show={taxType === ''} />
      <RenderTaxNumber data={data} formik={formik} show={taxNumber === ''} />
      <RenderEmail data={data} formik={formik} show={email === ''} />
      <RenderLocation data={data} formik={formik} show={location === ''} />
      <RenderPostalCode data={data} formik={formik} show={postalCode === ''} />
      <RenderProvince data={data} formik={formik} show={province === ''} />
      <RenderStreet data={data} formik={formik} show={street === ''} />
      <RenderStreetNumber data={data} formik={formik} show={streetNumber === ''} />

      <ArticleStyled className="items-center">
        <SubmitStyled type="submit">{t(data).CUSTOMER.FORM.SUBMIT}</SubmitStyled>
      </ArticleStyled>
    </FormCustomerStyled>
  );
};

export { CustomerForm };
